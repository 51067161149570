import { AppScriptStatus } from '../scripts-list/scripts-list';

export interface ScriptHistoryStateInterface {
  selectedResultPerPage: Record<string, string>;
  searchValue: string;
  scriptStatus: {
    label: string;
    value: string;
  };
  date: string;
}

export enum DateProperties {
  Date = 'date',
}

export interface ScriptHistoryRowInterfce {
  script: {
    url: string;
  };
  app: {
    id: number;
    name: string;
  };
  history: HistoryRowInterface[];
}

export interface HistoryRowInterface {
  action: AppScriptStatus;
  created_at: string;
  id: number;
  script: {
    id: number;
    url: string;
    created_at: string;
  };
  store: {
    uuid: string;
    url: string;
  };
  partner: {
    name: string;
  };
}

export interface ScriptHistoryFilterParamsInterface {
  search?: string;
  status?: string;
  date_from?: string;
  date_to?: string;
}
