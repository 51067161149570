import BaseModel from '@/api/models/BaseModel';
import { ScriptRowResponse } from '@/domains/pd-admin/types/administration/js-apps/scripts-list/scripts-list';

class AllScriptsResponseModel extends BaseModel {
  public scripts: ScriptRowResponse[];

  constructor(arg: any) {
    super(arg);
    this.scripts = arg.scripts;
  }
}

export default AllScriptsResponseModel;
