import {
  ScriptStatusDropdownEnum,
  ScriptStatusDropdownValuesEnum,
} from '@/domains/pd-admin/types/administration/js-apps/scripts-list/scripts-list';

export const SCRIPT_STATUS = [
  { label: ScriptStatusDropdownEnum.All, value: ScriptStatusDropdownValuesEnum.All },
  { label: ScriptStatusDropdownEnum.PendingReview, value: ScriptStatusDropdownValuesEnum.PendingReview },
  { label: ScriptStatusDropdownEnum.Published, value: ScriptStatusDropdownValuesEnum.Published },
  { label: ScriptStatusDropdownEnum.Rejected, value: ScriptStatusDropdownValuesEnum.Rejected },
];
