import Vue from 'vue';
import { defineComponent, onBeforeMount } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidInput,
  ZidButton,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidLoader,
  ZidDatepicker,
  ZidTable,
  ZidTableRow,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRowGroup,
} from '@zidsa/ui';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EyeIcon from '@/assets/icons/domains/pd-admin/EyeIcon.vue';
import ExitIcon from '@/assets/icons/domains/pd-admin/ExitIcon.vue';
import SendIcon from '@/assets/icons/domains/pd-admin/SendIcon.vue';
import useScriptsList from '@/domains/pd-admin/helpers/administration/js-apps/scripts-list/useScriptsList';
import { AppScriptStatus } from '@/domains/pd-admin/types/administration/js-apps/scripts-list/scripts-list';
import { getAllScripts, updateScriptStatus } from '@/domains/pd-admin/api/administration/js-apps/js-apps.ts';
import ErrorModel from '@/api/models/ErrorModel.ts';

export default defineComponent({
  components: {
    BaseText,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidButton,
    ZidInput,
    BaseIcon,
    SearchIcon,
    EyeIcon,
    ExitIcon,
    SendIcon,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidLoader,
    ZidDatepicker,
    ZidTable,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    ZidTableRowGroup,
  },
  setup() {
    const {
      scriptsListState,
      resultPerPage,
      v$,
      originalScripts,
      filteredScripts,
      displayedScripts,
      scriptStatus,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      ScriptsListInputProperty,
      ScriptsListSelectProperties,
      isLoading,
      onInputDataUpdate,
      onSelectionDataChange,
      onPreviousClick,
      onNextClick,
    } = useScriptsList();

    onBeforeMount(async () => {
      await fetchScripts();
    });

    const fetchScripts = async () => {
      isLoading.value = true;

      const response = await getAllScripts();
      if (response instanceof ErrorModel || !response.scripts) {
        isLoading.value = false;
        return;
      }
      originalScripts.list = response.scripts;
      const initialTableRecordCount = response.scripts.length >= 10 ? 10 : response.scripts.length;
      displayedScripts.list = response.scripts.slice(0, initialTableRecordCount);
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = initialTableRecordCount;

      isLoading.value = false;
    };

    const extractScriptStatus = (status) => {
      switch (status) {
        case AppScriptStatus.RequireReview:
          return 'in review';
        case AppScriptStatus.RequireUpdate:
          return 'Require update';
        case AppScriptStatus.Published:
          return 'published';
        case AppScriptStatus.Rejected:
          return 'rejected';
        default:
          return '';
      }
    };

    const onViewDetailsClicked = (script) => {
      router
        .push({
          name: RoutingRouteEnum.Admin_ScriptHistory,
          params: {
            id: `${script.script_id}`,
            appID: `${script.app_id}`,
          },
        })
        .catch((err) => {
          /**/
        });
    };

    const onRepublishClicked = async (script) => {
      const res = await updateScriptStatus(script.script_id, AppScriptStatus.Published);
      if (res instanceof ErrorModel || res.status !== 'success') {
        Vue.$toast.error('Could not publish script');
        return;
      }
      Vue.$toast.success('Script has been published');
      await fetchScripts();
    };

    const onUnpublishClicked = async (script) => {
      const res = await updateScriptStatus(script.script_id, AppScriptStatus.Rejected);
      if (res instanceof ErrorModel || res.status !== 'success') {
        Vue.$toast.error('Could not unpublish script');
        return;
      }
      Vue.$toast.success('Script has been unpublished');
      await fetchScripts();
    };

    return {
      scriptsListState,
      resultPerPage,
      v$,
      originalScripts,
      filteredScripts,
      displayedScripts,
      scriptStatus,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      ScriptsListInputProperty,
      ScriptsListSelectProperties,
      isLoading,
      RoutingRouteEnum,
      AppScriptStatus,
      onInputDataUpdate,
      onSelectionDataChange,
      onPreviousClick,
      onNextClick,
      extractScriptStatus,
      onViewDetailsClicked,
      onRepublishClicked,
      onUnpublishClicked,
    };
  },
});
