import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import AllScriptsResponseModel from '@/domains/pd-admin/models/administration/js-apps/all-scripts-response-model';
import { AppScriptStatus } from '@/domains/pd-admin/types/administration/js-apps/scripts-list/scripts-list';

const ENDPOINT_URL = '/market/admin/javascript-apps';

export const getAllScripts = (): Promise<AllScriptsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}`)
    .then((response) => new AllScriptsResponseModel(response))
    .catch((error) => error);
};

export const filterScripts = (
  searchValue: string | null,
  scriptStatus: string | null,
): Promise<AllScriptsResponseModel | ErrorModel> => {
  const search = `${
    searchValue !== null && searchValue !== '' ? `search=${searchValue}${scriptStatus !== null ? '&' : ''}` : ``
  }`;

  const status = `${scriptStatus !== null ? `status=${scriptStatus}` : ``}`;

  return httpClient
    .get(`${ENDPOINT_URL}?${search}${status}`)
    .then((response) => new AllScriptsResponseModel(response))
    .catch((error) => error);
};

export const updateScriptStatus = (scriptID: number, status: AppScriptStatus): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/${scriptID}/status`, {
      status,
    })
    .then((response) => new AllScriptsResponseModel(response))
    .catch((error) => error);
};
