export interface ScriptsListStateInterface {
  selectedResultPerPage: Record<string, string>;
  searchValue: string;
  scriptStatus: {
    label: string;
    value: string;
  };
}

export enum ScriptStatusDropdownEnum {
  All = 'All',
  PendingReview = 'Pending Review',
  Published = 'Published',
  Rejected = 'Rejected',
}

export enum ScriptStatusDropdownValuesEnum {
  All = '',
  PendingReview = '0',
  Published = '2',
  Rejected = '3',
}

export enum ScriptsListInputProperty {
  SearchValue = 'searchValue',
}

export enum ScriptsListSelectProperties {
  SelectedResultPerPage = 'selectedResultPerPage',
  ScriptStatus = 'scriptStatus',
}

export enum AppScriptStatus {
  RequireReview = 0,
  RequireUpdate = 1,
  Published = 2,
  Rejected = 3,
}

export interface ScriptRowResponse {
  script_id: number;
  app_id: number;
  url: string;
  status: AppScriptStatus;
  app_name: string;
  version: string;
  store_uuid: string;
  store_url: string;
}
