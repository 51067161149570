import BaseModel from '@/api/models/BaseModel';
import { AppScriptHistoryRowInterfce } from '@/domains/pd-admin/types/administration/js-apps/app-scripts-history/app-scripts-history';

class AppScriptsHistoryResponseModel extends BaseModel {
  public script_history: AppScriptHistoryRowInterfce;

  constructor(arg: any) {
    super(arg);
    this.script_history = arg.script_history;
  }
}

export default AppScriptsHistoryResponseModel;
