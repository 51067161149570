import BaseModel from '@/api/models/BaseModel';
import { ScriptHistoryRowInterfce } from '@/domains/pd-admin/types/administration/js-apps/script-history/script-history';

class ScriptHistoryResponseModel extends BaseModel {
  public script_history: ScriptHistoryRowInterfce;

  constructor(arg: any) {
    super(arg);
    this.script_history = arg.script_history;
  }
}

export default ScriptHistoryResponseModel;
