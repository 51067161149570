import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ScriptHistoryResponseModel from '@/domains/pd-admin/models/administration/js-apps/script-history-response-model';
import { ScriptHistoryFilterParamsInterface } from '@/domains/pd-admin/types/administration/js-apps/script-history/script-history';

const ENDPOINT_URL = '/market/admin/javascript-apps';

export const getScriptHistory = (scriptID: number): Promise<ScriptHistoryResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${scriptID}/history`)
    .then((response) => new ScriptHistoryResponseModel(response))
    .catch((error) => error);
};

export const filterScriptHistory = (
  scriptID: number,
  searchValue: string | null,
  scriptStatus: string | null,
  dateValue: string | null,
): Promise<ScriptHistoryResponseModel | ErrorModel> => {
  const filterParams: ScriptHistoryFilterParamsInterface = {};

  if (searchValue) {
    filterParams.search = searchValue;
  }

  if (scriptStatus) {
    filterParams.status = scriptStatus;
  }

  if (dateValue) {
    filterParams.date_from = dateValue;
    filterParams.date_to = dateValue;
  }

  return httpClient
    .get(`${ENDPOINT_URL}/${scriptID}/history`, {
      params: filterParams,
    })
    .then((response) => new ScriptHistoryResponseModel(response))
    .catch((error) => error);
};
