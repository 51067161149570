import { defineComponent, onBeforeMount, ref, unref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidInput,
  ZidButton,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidDatepicker,
  ZidLoader,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import dayjs from 'dayjs';
import router from '@/router';
import ErrorModel from '@/api/models/ErrorModel.ts';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EyeIcon from '@/assets/icons/domains/pd-admin/EyeIcon.vue';
import ExitIcon from '@/assets/icons/domains/pd-admin/ExitIcon.vue';
import SendIcon from '@/assets/icons/domains/pd-admin/SendIcon.vue';
import useScriptsHistory from '@/domains/pd-admin/helpers/administration/js-apps/script-history/useScriptsHistory';
import { getScriptHistory } from '@/domains/pd-admin/api/administration/js-apps/script-history.ts';
import { AppScriptStatus } from '@/domains/pd-admin/types/administration/js-apps/scripts-list/scripts-list';

export default defineComponent({
  components: {
    BaseText,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidButton,
    ZidInput,
    BaseIcon,
    SearchIcon,
    EyeIcon,
    ExitIcon,
    SendIcon,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidDatepicker,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup(_, ctx) {
    const scriptID = ref(Number(ctx.root.$route.params.id));
    const appID = ref(Number(ctx.root.$route.params.appID));

    const {
      scriptHistoryState,
      resultPerPage,
      v$,
      originalScripts,
      filteredScripts,
      displayedScripts,
      scriptStatus,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      ScriptsListInputProperty,
      ScriptsListSelectProperties,
      DateProperties,
      isLoading,
      applicationName,
      scriptUrl,
      onInputDataUpdate,
      onSelectionDataChange,
      onDatepickerChange,
      onPreviousClick,
      onNextClick,
    } = useScriptsHistory(unref(scriptID));

    onBeforeMount(async () => {
      isLoading.value = true;

      const response = await getScriptHistory(unref(scriptID));
      if (response instanceof ErrorModel || !response.script_history) {
        isLoading.value = false;
        return;
      }
      applicationName.value = response.script_history.app.name;
      scriptUrl.value = response.script_history.script.url;
      originalScripts.list = response.script_history.history;
      const initialTableRecordCount =
        response.script_history.history.length >= 10 ? 10 : response.script_history.history.length;
      displayedScripts.list = response.script_history.history.slice(0, initialTableRecordCount);
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = initialTableRecordCount;

      isLoading.value = false;
    });

    const extractScriptStatus = (status) => {
      switch (status) {
        case AppScriptStatus.RequireReview:
          return 'in review';
        case AppScriptStatus.RequireUpdate:
          return 'Require update';
        case AppScriptStatus.Published:
          return 'published';
        case AppScriptStatus.Rejected:
          return 'rejected';
        default:
          return '';
      }
    };

    const onShowAllAppHistoryClick = () => {
      router.push({
        name: RoutingRouteEnum.Admin_AppScriptHistory,
        params: {
          appID: appID.value,
        },
      });
    };

    return {
      RoutingRouteEnum,
      scriptHistoryState,
      resultPerPage,
      applicationName,
      scriptUrl,
      v$,
      originalScripts,
      filteredScripts,
      displayedScripts,
      scriptStatus,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      AppScriptStatus,
      ScriptsListInputProperty,
      ScriptsListSelectProperties,
      DateProperties,
      isLoading,
      dayjs,
      onInputDataUpdate,
      onSelectionDataChange,
      onDatepickerChange,
      onPreviousClick,
      onNextClick,
      extractScriptStatus,
      onShowAllAppHistoryClick,
    };
  },
});
