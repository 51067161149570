import { reactive, watch, ref, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import dayjs from 'dayjs';
import {
  ScriptsListInputProperty,
  ScriptsListSelectProperties,
  ScriptStatusDropdownValuesEnum,
} from '@/domains/pd-admin/types/administration/js-apps/scripts-list/scripts-list';
import { SCRIPT_STATUS } from './dropdownInfo';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import {
  DateProperties,
  HistoryRowInterface,
  ScriptHistoryStateInterface,
} from '@/domains/pd-admin/types/administration/js-apps/script-history/script-history';
import ErrorModel from '@/api/models/ErrorModel';
import { filterAppScriptsHistory } from '@/domains/pd-admin/api/administration/js-apps/app-scripts-history';

const useAppScriptsHistory = (appID: number) => {
  const applicationName = ref('');

  const scriptHistoryState = reactive<ScriptHistoryStateInterface>({
    selectedResultPerPage: {
      label: '10',
      value: '10',
    },
    searchValue: '',
    scriptStatus: {
      label: '',
      value: '',
    },
    date: '',
  });

  const rules = {
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    searchValue: {},
    scriptStatus: {
      label: {},
      value: {},
    },
  };

  const RESULT_PER_PAGE = [];

  for (let i = 10; i <= 100; i += 10) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const scriptStatus = reactive({
    options: SCRIPT_STATUS,
  });

  const originalScripts = reactive<Record<string, HistoryRowInterface[]>>({
    list: [],
  });

  const filteredScripts = reactive<Record<string, HistoryRowInterface[]>>({
    list: [],
  });

  const displayedScripts = reactive<Record<string, HistoryRowInterface[]>>({
    list: [],
  });

  const currentFilterRange = reactive<any>({
    startIndex: -1,
    endIndex: -1,
  });

  const isFilterByScriptStatus = ref(false);

  const isFilterBySearch = ref(false);

  const isFilterByDate = ref(false);

  const isFilterApplied = ref(false);

  const isLoading = ref(false);

  const v$ = useVuelidate(rules, scriptHistoryState);

  const onInputDataUpdate = (keyUp: KeyboardEvent, dataProperty: ScriptsListInputProperty) => {
    const target = keyUp.target as HTMLTextAreaElement;
    v$.value[dataProperty].$touch();
    scriptHistoryState[dataProperty] = target.value;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      fetchByFilters();
    }
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: ScriptsListSelectProperties) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    scriptHistoryState[property].label = selectedValue.label;
    scriptHistoryState[property].value = selectedValue.value;
  };

  const onDatepickerChange = (changedValue: Record<string, string>, dataProperty: DateProperties) => {
    scriptHistoryState[dataProperty] = changedValue.value;
    fetchByFilters();
  };

  watch(
    () => filteredScripts,
    () => {
      applyPageRangeFilter();
    },
    { deep: true },
  );

  watch(
    [isFilterByScriptStatus, isFilterBySearch, isFilterByDate],
    ([newFilterByScriptStatus, newFilterBySearch, newFilterByDate]) => {
      isFilterApplied.value = newFilterByScriptStatus || newFilterBySearch || newFilterByDate;
      applyPageRangeFilter();
    },
  );

  watch(
    () => scriptHistoryState.selectedResultPerPage,
    (newCountOfResultPerPage) => {
      if (originalScripts.list.length >= Number(newCountOfResultPerPage.value)) {
        displayedScripts.list = !isFilterApplied.value
          ? originalScripts.list.slice(0, Number(newCountOfResultPerPage.value))
          : filteredScripts.list.slice(0, Number(newCountOfResultPerPage.value));
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = Number(newCountOfResultPerPage.value);
      } else {
        displayedScripts.list = !isFilterApplied.value
          ? originalScripts.list.slice(0, originalScripts.list.length)
          : filteredScripts.list.slice(0, originalScripts.list.length);
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = originalScripts.list.length;
      }
    },
    { deep: true },
  );

  watch(
    () => scriptHistoryState.scriptStatus,
    () => {
      fetchByFilters();
    },
    { deep: true },
  );

  const fetchByFilters = async () => {
    isLoading.value = true;
    let selectedScriptDate = null;

    if (scriptHistoryState.date) {
      selectedScriptDate = dayjs(scriptHistoryState.date).format('YYYY-MM-DD');
    }

    const selectedScriptStatus =
      scriptHistoryState.scriptStatus.value === ScriptStatusDropdownValuesEnum.All
        ? null
        : scriptHistoryState.scriptStatus.value;

    const filterRes = await filterAppScriptsHistory(
      appID,
      scriptHistoryState.searchValue ?? null,
      selectedScriptStatus,
      selectedScriptDate ?? null,
    );

    if (filterRes instanceof ErrorModel || !filterRes.script_history) {
      isLoading.value = false;
      return;
    }
    if (!filterRes.script_history.history) {
      originalScripts.list = [];
      displayedScripts.list = [];
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = 0;
      isLoading.value = false;
      return;
    }
    originalScripts.list = filterRes.script_history.history;
    const initialTableRecordCount =
      filterRes.script_history.history.length >= 10 ? 10 : filterRes.script_history.history.length;
    displayedScripts.list = filterRes.script_history.history.slice(0, initialTableRecordCount);
    currentFilterRange.startIndex = 0;
    currentFilterRange.endIndex = initialTableRecordCount;
    isLoading.value = false;
  };

  const applyPageRangeFilter = () => {
    let newAppsListEndIndex;

    if (!isFilterApplied.value) {
      newAppsListEndIndex =
        originalScripts.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : originalScripts.list.length;
      displayedScripts.list = originalScripts.list.slice(currentFilterRange.startIndex, newAppsListEndIndex);
    } else {
      newAppsListEndIndex =
        filteredScripts.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : filteredScripts.list.length;
      displayedScripts.list = filteredScripts.list.slice(currentFilterRange.startIndex, newAppsListEndIndex);
    }
  };

  const onPreviousClick = () => {
    currentFilterRange.startIndex -= Number(scriptHistoryState.selectedResultPerPage.value);
    currentFilterRange.endIndex -= Number(scriptHistoryState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const onNextClick = () => {
    currentFilterRange.startIndex += Number(scriptHistoryState.selectedResultPerPage.value);
    currentFilterRange.endIndex += Number(scriptHistoryState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const isNextButtonDisabled = computed(() => {
    if (isFilterApplied.value) {
      return currentFilterRange.endIndex >= filteredScripts.list.length;
    } else {
      return currentFilterRange.endIndex >= originalScripts.list.length;
    }
  });

  return {
    scriptHistoryState,
    resultPerPage,
    v$,
    originalScripts,
    filteredScripts,
    displayedScripts,
    scriptStatus,
    currentFilterRange,
    isFilterApplied,
    isNextButtonDisabled,
    ScriptsListInputProperty,
    ScriptsListSelectProperties,
    DateProperties,
    isLoading,
    applicationName,
    onInputDataUpdate,
    onSelectionDataChange,
    onDatepickerChange,
    onPreviousClick,
    onNextClick,
  };
};

export default useAppScriptsHistory;
